import { useUdpTracking } from './use-udp-tracking';
import { usePersonalizationSettings } from '../providers/DataProvider/DataContext';

/**
 * no item available in case of a highlight section
 */

export const useUdpSectionTracking = () => {
  const {
    isActive
  } = usePersonalizationSettings();
  const track = useUdpTracking('uievent');
  return sectionClickEventData => {
    if (isActive) {
      const itemData = sectionClickEventData.item ? {
        item_urn: sectionClickEventData.item.urn,
        action_value: sectionClickEventData.item.index
      } : {};
      track({
        schema_version: '0.1.0',
        timestamp: Date.now(),
        type: 'ui_event',
        action_type: 'click',
        action_source: sectionClickEventData.sectionMeta.representationName,
        action_element: sectionClickEventData.sectionMeta.sectionTitle,
        action_name: 'navigation',
        action_location: sectionClickEventData.sectionMeta.sectionId,
        ...itemData
      });
    }
  };
};